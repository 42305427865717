import { Injectable, Output, EventEmitter } from "@angular/core";

import isEqual from 'lodash.isequal';

import { Cart } from '../model/cart';
import { Item } from '../model/orm/item.model';
import { ICartRecord } from '../model/cartrecord.interface';
import { ISize } from '../model/orm/size.interface';
import { AppService } from './app.service';
import { Currency } from '../model/orm/currency.model';
import { CountriesRepository } from './repositories/countries.repository';
import { Country } from '../model/orm/country.model';
import { IOrderDTO } from '../model/dto/order.dto';
import { DataService } from './data.service';
import { IOrderResponseDTO } from '../model/dto/order.response.dto';
import { ILiqpayDTO } from '../model/dto/liqpay.dto';
import { IAnswer } from '../model/answer.interface';
import { RetturnDTO } from '../model/dto/retturn.dto';
import { SettingsRepository } from './repositories/settings.repository';
import {IMonobankDTO} from "../model/dto/monobank.dto";
import {ICartItemDTO} from "../model/dto/cartitem.dto";

@Injectable()
export class CartService {
    @Output() updateItemEvent = new EventEmitter<string>();
    @Output() addCartInfoMessage = new EventEmitter<object>();
    public cartUserId: number = Date.now();
    public cart: Cart = new Cart();
    public isCheckout = false;
    public currentCountry: Country = null; // вспомогательный параметр, служит только для того, чтобы не выполнять поиск страны непосредственно во время вычисления стоимостей доставки

    constructor(
        private appService: AppService,
        private dataService: DataService,
        private countriesRepository: CountriesRepository,
        private settingsRepository: SettingsRepository,
    ) {
        this.init();
    }

    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get q(): number {return this.cart.records.length ? this.cart.records.map(r => r.q).reduce((acc, x) => acc + x) : 0;}
    get sum(): number {
        let productsCost: number = this.cart.records.length ? this.cart.records.map(r => r.q * Math.ceil(r.item.price * this.currentCurrency.rate)).reduce((acc, x) => acc + x) : 0;
        return productsCost + (this.cart.bag ? this.bagCost : 0);
    }
    get shippingCost(): number {return this.currentCountry ? Math.ceil(this.currentCountry.shipping * this.currentCurrency.rate) : 0;}
    get bagCost(): number {return Math.ceil(this.settingsRepository.bagCost * this.currentCurrency.rate);}
    get freeShippingLimit(): number {return (this.currentCountry && this.currentCountry.free !== null) ? Math.ceil(this.currentCountry.free * this.currentCurrency.rate) : 0;}
    get discount(): number {
        if (this.cart.promocode) {
            if (this.cart.promocode.type === "amount") {
                return Math.round(this.cart.promocode.discount * this.currentCurrency.rate);
            } else if (this.cart.promocode.type === "percent") {
                return Math.round(this.sum / 100 * this.cart.promocode.discount * this.currentCurrency.rate);
            } else if (this.cart.promocode.type === "n=n-1") {
                // если в корзине больше одного товара, то скидка равна цене самого дешевого из них
                if (this.q > 1) {
                    return Math.round(Math.min(...this.cart.records.map(r => r.item.price)) * this.currentCurrency.rate);
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }

        return 0;
    }
    get total(): number {return this.sum + this.shippingCost - this.discount;}
    get related(): Item[] {
        let uniqueRelated: Item[] = [];
        if(this.cart.records.length > 0){
            this.cart.records.forEach(r => uniqueRelated = [...uniqueRelated, ...r.item.related.filter(r => !uniqueRelated.map(ur => ur.id).includes(r.id))]);
        }
        return uniqueRelated;
    }

    private async init(): Promise<void> {
        if (this.appService.isBrowser) {
            let cartUserId = localStorage.getItem('cartUserId');
            if(cartUserId) {
                this.cartUserId = parseInt(cartUserId);
            } else {
                localStorage.setItem('cartUserId', this.cartUserId + '');
            }
            let data: string = localStorage.getItem("cart");
            this.cart = data ? JSON.parse(data) : new Cart();
            if(typeof this.cart.old_records == 'undefined' || typeof this.cart.timer == 'undefined') {
              let broken_data = this.cart;
              this.cart = new Cart();
              this.cart.cartUserId = this.cartUserId;
              if(typeof broken_data.records != 'undefined'){
                  this.cart.records = broken_data.records;
                  // this.updateCartReserve();
              }
            }
            this.cart.cartUserId = this.cartUserId;

            // setInterval(() => {
            //   console.log(this.cart);
            //   debugger;
            //     if(this.cart.records.length && this.cart.records[0].secondsLeft > 0 && this.cart.timer.secondsLeft >= 0) {
            //         this.cart.timer.minutes = Math.floor(this.cart.timer.secondsLeft / 60 );
            //         this.cart.timer.seconds = this.cart.timer.secondsLeft - (this.cart.timer.minutes * 60);
            //         if (this.cart.timer.minutes <= 2 && this.cart.timer.secondsLeft > 0) {
            //             this.addCartInfoMessage.emit({
            //                 message: 'Ваш резерв майже закінчився.',
            //                 showBtn: false,
            //                 show: true
            //             });
            //         } else if (this.cart.timer.secondsLeft == 0) {
            //             this.addCartInfoMessage.emit({
            //                 message: 'Ваш резерв скасовано.',
            //                 showBtn: false,
            //                 show: true
            //             });
            //             this.clear();
            //         }
            //         this.cart.timer.secondsLeft--;
            //         this.save();
            //     }
            // }, 1000);
        }
    }

    public async add(item: Item, size: ISize, q: number): Promise<boolean> {
        let exhausted: boolean = false;
        let record: ICartRecord = this.cart.records.find(r => r.item.id === item.id && r.size.id === size.id);

        if (record) {
            if (size.q >= record.q + q) {
                record.q += q;
            } else {
                exhausted = true;
            }
        } else {
            this.cart.records.push({
                id: Date.now(),
                item,
                size,
                q,
                created_at: 0,
                secondsLeft: 0,
                errorBeforeCheckout: false,
                errorBeforeCheckoutQuantity: null
            });
        }
        this.save();
        return !exhausted;
    }

    public updateCartReserve () {
        new Promise((resolve, reject) => {
            this.dataService.updateCartReserve(this.cartUserId, this.cart.records).subscribe(res => {
                if (res.statusCode === 200) {
                    this.cart.records = res.data;
                    if (this.cart.records[0]) {
                      this.cart.timer.secondsLeft = this.cart.records[0].secondsLeft
                    }
                }
                resolve(res.statusCode);
            }, err => {
                reject(err.message);
            });
        });
    }

    public save(clearDontCall = true): void {
        if(this.isCheckout && this.isRecordsChanged()){
            // if (!this.cart.newTimer) {
            //   const timeNow = new Date();
            //
            //   this.cart.newTimer = timeNow.getTime() + 600000;
            //   // this.cart.newTimer = timeNow.getTime() + 60000;
            // }
            // this.updateCartReserve();
            this.cart.old_records = this.cart.records;
        }

        if (this.appService.isBrowser) {
            if (clearDontCall) {
                this.cart.dont_call_me = false;
            }
            if(localStorage) localStorage.setItem("cart", JSON.stringify(this.cart));
        }

        // if (!this.cart.records.length) {
        //   this.cart.newTimer = null;
        // }

        this.updateItemEvent.emit();
    }

    public isRecordsChanged(): boolean {
      let c_r_state = [];
      let o_r_state = [];
      this.cart.records.forEach((cartItem) => {
        c_r_state.push({
          key: cartItem.item.id + '-' + cartItem.size.id,
          q: cartItem.q
        });
      });
      this.cart.old_records.forEach((cartItem) => {
        o_r_state.push({
          key: cartItem.item.id + '-' + cartItem.size.id,
          q: cartItem.q
        });
      });

      return !isEqual(c_r_state, o_r_state);
    }

    public decreaseRecord(record: ICartRecord): void {
        record.q--;
        !record.q ? this.removeRecord(record) : null;
        this.save();
    }

    public increaseRecord(record: ICartRecord): void {
        if(record.q + 1 <= record.size.q){
            record.q++;
        }
        this.save();
    }

    public removeRecord(record: ICartRecord): void {
        let index: number = this.cart.records.findIndex(r => r.item.id === record.item.id && r.size.id === record.size.id);
        this.cart.records.splice(index, 1);
        this.save();
    }

    public sendOrder(lang_id: number, user_id: number, currency_id: number): Promise<IOrderResponseDTO> {
        const cookieGa = this.getCookie('_ga');

        return new Promise((resolve, reject) => {
            let dto: IOrderDTO = {
                cart: this.cart,
                lang_id,
                user_id,
                currency_id,
                ga: cookieGa
            };
            this.dataService.orderCreate(dto).subscribe(res => {
                resolve(res.data);
            }, err => {
                reject(err.message);
            });
        });
    }

    public sendOrderNotification(id: number): Promise<IAnswer<void>> {
        return new Promise((resolve, reject) => {
            this.dataService.orderNotifyAboutCreation(id).subscribe(res => resolve(res), err => reject(err.message));
        });
    }

    public getCookie(name: string): string | null {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));

      return match ? decodeURIComponent(match[2]) : null;
    }

    public sendOrderToMoysklad(id: number): Promise<IAnswer<void>> {
        return new Promise((resolve, reject) => {
            this.dataService.orderToMoysklad(id).subscribe(res => resolve(res), err => reject(err.message));
        });
    }

    public getLiqpayData(orderId: number): Promise<ILiqpayDTO> {
        return new Promise((resolve, reject) => {
            this.dataService.orderLiqpayBuild(orderId).subscribe(res => {
                resolve(res.data);
            }, err => {
                reject(err.message);
            });
        });
    }

    public getMonobankData(orderId: number): Promise<IMonobankDTO> {
        return new Promise((resolve, reject) => {
          console.log(this.dataService)
            this.dataService.orderMonobankBuild(orderId).subscribe(res => {
                resolve(res.data);
            }, err => {
                reject(err.message);
            });
        });
    }

    public clear(): void {
        // this.cart.records = [];
        this.cart.reserve = [];
        this.cart.bag = false;
        this.cart.promocode = null;
        this.cart.newTimer = null;
        this.save();
    }

    public sendRetturn(dto: RetturnDTO): Promise<number> {
        return new Promise((resolve, reject) => {
            this.dataService.orderRetturn(dto).subscribe(res => {
                if (res.statusCode === 200) {
                    resolve(res.data);
                } else {
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });
        });
    }

    public sendRetturnNotification(id: number): Promise<IAnswer<void>> {
        return new Promise((resolve, reject) => {
            this.dataService.orderNotifyAboutReturn(id).subscribe(res => resolve(res), err => reject(err.message));
        });
    }

    public applyPromocode(code: string): Promise<number> {
        return new Promise((resolve, reject) => {
            this.dataService.promocodeApply(code).subscribe(res => {
                if (res.statusCode === 200) {
                    this.cart.promocode = res.data;
                    this.save();
                }

                resolve(res.statusCode);
            }, err => {
                reject(err.message);
            });
        });
    }
}
