import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef, EventEmitter, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { Cat } from 'src/app/model/orm/cat.model';
import { Item } from 'src/app/model/orm/item.model';
import { AppService } from 'src/app/services/app.service';
import { CatsRepository } from 'src/app/services/repositories/cats.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { ItemsBySlugRepository } from 'src/app/services/repositories/itemsbyslug.repository';
import { Currency } from 'src/app/model/orm/currency.model';
import { ISize } from 'src/app/model/orm/size.interface';
import { ItemsMoreRepository } from 'src/app/services/repositories/itemsmore.repository';
import { SettingsRepository } from 'src/app/services/repositories/settings.repository';
import { BehaviorSubject } from 'rxjs';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Page } from 'src/app/model/orm/page.model';
import { CartService } from 'src/app/services/cart.service';
import { Cart } from 'src/app/model/cart';

import { StatisticsGoogleService } from 'src/app/services/statistics-google.service';
import { StatisticsFacebookService } from 'src/app/services/statistics-facebook.service';
import { StatisticsConverterService } from 'src/app/services/statistics-converter.service';

import { StatisticsGoogleProductDetailView } from 'src/app/model/statistics_GoogleProductDetailView';
import { StatisticsGoogleAddToCart } from 'src/app/model/statistics_GoogleAddToCart';
import { StatisticsFacebook } from 'src/app/model/statistics_Facebook';

import { CurrenciesRepository } from 'src/app/services/repositories/currencies.repository';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: "item-page",
  templateUrl: "./item.page.html",
  styleUrls: ["./item.page.scss"],
})
export class ItemPage implements OnInit, OnDestroy {
  public cat: Cat = null;
  public catReady: boolean = false;
  public catslug: string = null;
  public item: Item = null;
  public itemReady: boolean = false;
  private itemslug: string = null;
  public q: number = 1;
  public checkStatus: string = '';
  public selectedSize: ISize;
  public moreinMode: string = "cat"; // color | cat
  public waitlistActive: boolean = false;
  public collectionStartActive: boolean = false;
  public nosizemodalActive: boolean = false;
  public nosizemodalShowed: boolean = false;
  public reviewsFormActive: boolean = false;
  @ViewChild("reviews", {static: false}) reviewsRef: ElementRef;
  public sizeguidesActive: boolean = false;
  public detailsMode: number = 0;
  public exhausted: boolean = false;
  public galItemPicturesPopupShown = false;
  public noizeModalClose: EventEmitter<boolean> = new EventEmitter();
  public interval: null | ReturnType<typeof setInterval> = null;
  public cart_enabled: boolean = true;
  public availableCartId = [1681839049893, 1712240584979, 1720194424283];
  public isShowPreorderBudget: boolean = false;


  @ViewChild('itemAdd', {static: false}) private itemAdd: ElementRef<HTMLDivElement>;
  isItemAllScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(){
    if (this.itemAdd){
      const rect = this.itemAdd.nativeElement.getBoundingClientRect();
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isItemAllScrolledIntoView = bottomShown;
    }
  }

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private catsRepository: CatsRepository,
    private itemsyslugRepository: ItemsBySlugRepository,
    private itemsmoreRepository: ItemsMoreRepository,
    private pagesRepository: PagesRepository,
    private cartService: CartService,
    private statisticsGoogleService: StatisticsGoogleService,
    private statisticsFacebookService: StatisticsFacebookService,
    private statisticsConverterService: StatisticsConverterService,
    private currenciesRepository: CurrenciesRepository,
    private settingsRepository: SettingsRepository,
    private analyticsService: AnalyticsService
  ) {}

  get currentLang(): Lang {return this.appService.currentLang.value;}
  get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
  get price(): number {return Math.ceil(this.item.price * this.currentCurrency.rate);}
  get moreByColor(): Item[] {return this.itemsmoreRepository.xlByColor;}
  get moreByCat(): Item[] {return this.itemsmoreRepository.xlByCat;}
  get page(): Page {return this.pagesRepository.itemPage;}
  get isMobile(): boolean {return this.appService.isMobile();}
  get isShareable(): boolean {return this.isMobile && window && window.navigator && (window.navigator as any).share !== undefined;}
  get cart(): Cart {return this.cartService.cart;}
  get cartQ(): number {return this.cartService.q;}

  get currencies(): Currency[] {return this.currenciesRepository.xl;}

  public ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  public ngOnInit(): void {
    this.noizeModalClose.subscribe(() => {
      this.nosizemodalShowed = true;
    });

    let cart_enabled = this.settingsRepository.xl.find(x => x.p === "cart_enabled");
    this.cart_enabled = cart_enabled.v === "1" ? true : false;

    this.appService.headerAlwaysOpaque = true;
    this.appService.catmenumobileActive = true;
    this.appService.collmenumobileActive = false;
    this.route.params.subscribe(async p => {
      if (p['cat'] !== this.catslug || p['item'] !== this.itemslug) {
        try {
          this.catslug = p['cat'];
          this.itemslug = p['item'];
          this.catReady = false;
          this.itemReady = false;
          this.q = 1;
          this.detailsMode = 0;
          this.reviewsFormActive = (this.route.fragment as BehaviorSubject<string>).value === "reviews";
          this.cat = this.catsRepository.xl.find(x => x.slug === this.catslug);

          if (!this.cat) {
            this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
          } else {
            this.catReady = true;
            this.itemsyslugRepository.slug = this.itemslug;
            this.item = await this.itemsyslugRepository.load();
            console.log(this.item);
            // this.cartService.updateItemEvent.subscribe(async () => {
            //   this.item = await this.itemsyslugRepository.load();
            //   this.selectedSize = this.item.sizes[0];
            // });



            if (this.item.nosize) {
              this.nosizemodalActive = true;
            }
            let short = '';
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.has('short')) {
              short = urlParams.get('short');
            }
            if (short.length > 0) {
              this.item.waitlist = short;
            }

            this.itemReady = true;
            this.selectedSize = this.item.sizes[0];
            this.isShowPreorderBudget = !!this.item.sizes[0].preorder;
            this.checkStatus = this.item.status ? this.item.status.name['en'] : '';
            this.setMeta();
            this.moreinMode = "cat";
            this.itemsmoreRepository.dto = {itemId: this.item.id, catId: this.cat.id, colorId: this.item.color ? this.item.color.id : null};
            this.itemsmoreRepository.load();
            this.sendViewContentEvent();
          }

          const analyticsData = {
            currency: this.currentCurrency.name,
            value: this.item.price,
            items: [{
              item_id: this.item.slug,
              item_name: this.item.name.ua,
              price: this.item.price
            }]
          };

          this.analyticsService.sendEvent('view_item', analyticsData);

        } catch (err) {
          this.appService.showNotification(err, "error");
          err === "404: item not found" ? this.router.navigateByUrl(`/${this.currentLang.slug}/404`) : null;
        }
      } else {
        this.setMeta();
      }
    });

  }
  sendViewContentEvent() {
    let googleData: StatisticsGoogleProductDetailView
      = this.statisticsConverterService.toStatisticsGoogleProductDetailView(
      this.currentCurrency.name,
      this.item,
      this.cat,
      this.currentLang.slug,
      this.price.toString());
    let facebookData: StatisticsFacebook
      = this.statisticsConverterService.toStatisticsFacebookProductDetailsView(
      this.item,
      this.cat,
      this.currentLang.slug,
      this.currentCurrency.name,
      this.price.toString());

    this.statisticsGoogleService.viewProductDetails(googleData);
    this.statisticsFacebookService.viewContent(facebookData);
  }
  statistics_sendAddToCartEvent() {
    let currencyCode = this.currentCurrency.name;

    let facebookCurrency: Currency;
    for(let i = 0; i < this.currencies.length; i ++) {
      if (this.currencies[i].name !== 'UAH') {
        facebookCurrency = this.currencies[i];
        break;
      }
    }
    let facebookPrice = Math.ceil(this.item.price * facebookCurrency.rate);

    let googleData: StatisticsGoogleAddToCart
      = this.statisticsConverterService.toStatisticsGoogleAddToCart(
      currencyCode,
      this.item,
      this.cat,
      this.currentLang.slug,
      this.price.toString(),
      this.q
    );

    let facebookData: StatisticsFacebook
      = this.statisticsConverterService.toStatisticsFacebookAddToCart(
      this.item,
      this.cat,
      this.currentLang.slug,
      facebookCurrency.name,
      facebookPrice.toString());

    this.statisticsGoogleService.addToCart(googleData);
    this.statisticsFacebookService.addToCart(facebookData);
  }

  public onGalItemPicturesPopupClosed() {
    this.galItemPicturesPopupShown = false;
  }
  public showGalItemPicturesPopup(picture) {
    this.galItemPicturesPopupShown = true;
  }
  private setMeta(): void {
    this.appService.setTitle(this.item.title[this.currentLang.slug] || this.item.name[this.currentLang.slug]);
    this.appService.setMeta("keywords", this.item.keywords[this.currentLang.slug]);
    this.appService.setMeta("description", this.item.description[this.currentLang.slug]);
  }

  public increase() {
    if (this.q < this.selectedSize.q) {
      this.q++;
    }
  }

  public decrease() {
    if (this.q > 1) {
      this.q--;
    }
  }

  public scrollToReviews(): void {
    let elem: HTMLElement = this.reviewsRef.nativeElement;
    this.appService.smoothScroll(window.scrollY, elem.offsetTop - 80, 500, window);
  }

  public async toCart(): Promise<void> {
    const analyticsData = {
      currency: this.currentCurrency.name,
      value: this.item.price,
      items: [{
        item_id: this.item.slug,
        item_name: this.item.name.ua,
        quantity: this.q,
        price: this.item.price,
        size: this.selectedSize
      }]
    };
    this.analyticsService.sendEvent('add_to_cart', analyticsData);
    /*this.analyticsService.trackEvent('add_to_cart', {
      currency: "USD",
      value: 30.03,
      items: [
        {
          item_id: "SKU_12345",
          item_name: "Stan and Friends Tee",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          discount: 2.22,
          index: 0,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "green",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: 10.01,
          quantity: 3
        }
      ]
    });*/
    let res: boolean = await this.cartService.add(this.item, this.selectedSize, this.q);
    if (res) {
      this.appService.cartpanelActive = true;
      this.statistics_sendAddToCartEvent();
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      this.exhausted = true;
      setTimeout(() => this.exhausted = false, 2000);
    }
  }

  public share(): void {
    let navi: any = window.navigator;

    if (navi && navi.share) {
      navi.share({url: window.location.href, title: this.item.name[this.currentLang.slug]});
    }
  }

  public onCartClick(): void {
    this.appService.cartpanelActive = true;
  }

  public openStartCollectionModal() {
    this.collectionStartActive = true;
  }

  public toWaitList(): void {
    this.analyticsService.trackEvent('notify_cta', 'item_page');
    this.waitlistActive = true;
  }
}
