import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare var gtag: any;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  public trackEvent(eventName: string, eventDetails: any) {
    // if (environment.production) {
      gtag('event', eventName, {
        'event_label': eventName,
        'value': eventDetails
      });
    // }
  }

  public sendEvent(eventName: string, data: any) {
    gtag('event', eventName, data);
  }
}
