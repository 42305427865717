import { Model } from '../model';
import { IPicture } from './picture.interface';
import { ISize } from './size.interface';
import { Collection } from './collection.model';
import { IFeature } from './feature.interface';
import { IStatus } from './status.interface';
import { IColor } from './color.interface';

export class Item extends Model {
    public id: number;
    public code?: string;
    public price?: number;
    public original_price?: number;
    public slug?: string;
    public catslug?: string;
    public pictures?: IPicture[];
    public color?: IColor;
    public sizes?: ISize[];
    public collections?: Collection[];
    public features?: IFeature[];
    public status?: IStatus;
    public sizeStatus?: IStatus;
    public related?: Item[];
    public relatedpos: string;
    public reviewsRate?: number;
    public reviewsQ?: number;

    public name?: { ua: string; en: string };
    public h1?: Object;
    public contentshort?: Object;
    public content?: Object;
    public obmery?: Object;
    public material?: Object;
    public title?: Object;
    public keywords?: Object;
    public description?: Object;

    public variations?: Item[];
    public current?: boolean;
    public nosize?: boolean;
    public waitlist?: string;
    public sort: number; //remove when merge
}
